@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf')  format('truetype'), url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Light.woff') format('woff'), url('../fonts/ProximaNova-Light.ttf')  format('truetype'), url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Semibold.woff') format('woff'), url('../fonts/ProximaNova-Semibold.ttf')  format('truetype'), url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'SFUIDisplayBlack';
    src: 
    url('../fonts/SFUIDisplay-Black.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Black.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Black.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Black.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Black.svg#SFUIDisplay-Black') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayBold';
    src: 
    url('../fonts/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Bold.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Bold.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Bold.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayHeavy';
    src: 
    url('../fonts/SFUIDisplay-Heavy.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Heavy.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Heavy.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Heavy.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Heavy.svg#SFUIDisplay-Heavy') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayMedium';
    src: 
    url('../fonts/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Medium.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Medium.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Medium.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayRegular';
    src: 
    url('../fonts/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Regular.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Regular.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Regular.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg');

  }

@font-face {
    font-family: 'SFUIDisplaySemibold';
    src: 
    url('../fonts/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Semibold.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Semibold.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Semibold.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold') format('svg');
  }

  @font-face {
    font-family: 'SFUIDisplayLight';
    src: url('../fonts/SFUIDisplayLight.eot');
    src: url('../fonts/SFUIDisplayLight.eot') format('embedded-opentype'),
         url('../fonts/SFUIDisplayLight.woff2') format('woff2'),
         url('../fonts/SFUIDisplayLight.woff') format('woff'),
         url('../fonts/SFUIDisplayLight.ttf') format('truetype'),
         url('../fonts/SFUIDisplayLight.svg#SFUIDisplayLight') format('svg');
}